#hotspot .carousel-control-prev-icon {
    height: 30px !important;
    width: 30px !important;
    background-size: 100%, 100%;
    background-image:  url("../images/Previous.png") !important;
  }
  
  
#hotspot .carousel-control-next-icon {
    height: 30px !important;
    width: 30px !important;
    background-size: 100%, 100%;
    background-image:  url("../images/Next.png") !important;
  }

#hotspotModal img{
    max-width: 100%;
}  
  .hotspot {
    position: relative;
    display: inline-block;
    max-width:100%;
  }
  
  .hotspot img {
    cursor: initial !important;
    border: none !important;
    max-width:100%;
    display: block;
  }
  
  .hotspotIcon {
    position: absolute;
    cursor: pointer;
  }
