#interactive-image:hover {
  cursor: pointer;
}

.interactive-image-PreView{
  .image-uploading, img{
      height: 220px;
      width: 100%;
      object-fit: contain !important;
  }
}
.cardHeight{
  height: 318px;
  overflow: auto;
}
.interactive-imageView {
  .image-uploading, img{
      height: 250px;
      width: 100%;
      object-fit: contain !important;
  }
  
  @media screen and (max-height: 800px){
    .image-uploading{
        width: 100%;
    }
  }
}
.interactive-textstyle{
  text-align: justify;
  word-break: normal;
 }